// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VIcon_E4rnR._xs_iDjVH{height:1rem;width:1rem}.VIcon_E4rnR._small_ynukz{height:1.2rem;width:1.2rem}.VIcon_E4rnR._pre-medium_9DkEg{height:1.4rem;width:1.4rem}.VIcon_E4rnR._medium_Lx1vL{height:1.6rem;width:1.6rem}.VIcon_E4rnR._semi-large_h9YQy{height:2rem;width:2rem}.VIcon_E4rnR._large_-0Qu4{height:2.4rem;width:2.4rem}.VIcon_E4rnR._48_ZVmx2{height:4.8rem;width:4.8rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-tablet": "5.6rem",
	"header-h-mobile": "56px",
	"footer-h": "61.2rem",
	"mobile": "0",
	"tablet-sm": "768px",
	"tablet": "1024px",
	"laptop": "1280px",
	"desktop": "1440px",
	"large-desktop": "1920px",
	"VIcon": "VIcon_E4rnR",
	"_xs": "_xs_iDjVH",
	"_small": "_small_ynukz",
	"_pre-medium": "_pre-medium_9DkEg",
	"_medium": "_medium_Lx1vL",
	"_semi-large": "_semi-large_h9YQy",
	"_large": "_large_-0Qu4",
	"_48": "_48_ZVmx2"
};
module.exports = ___CSS_LOADER_EXPORT___;
