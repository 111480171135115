// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VPreloader_gjfp2{align-items:center;display:flex;justify-content:space-between}.VPreloader_gjfp2._medium_P3ZDA{height:.4rem;width:1.8rem}.VPreloader_gjfp2._medium_P3ZDA .dot_i58kL{height:.3rem;width:.3rem}.VPreloader_gjfp2._base_z8cdQ .dot_i58kL{background-color:#855aff}.VPreloader_gjfp2._dark_Qnwbc .dot_i58kL{background-color:#fff}.dot_i58kL{animation:animloader_tIGTj .8s linear infinite;animation-delay:.1s;display:inline-block;margin:0 auto;position:relative}.dot_i58kL:nth-child(2){animation-delay:.2s}.dot_i58kL:last-child{animation-delay:.3s}@keyframes animloader_tIGTj{0%{transform:translateY(0)}50%{transform:translateY(-.2rem)}to{transform:translateY(0)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-tablet": "5.6rem",
	"header-h-mobile": "56px",
	"footer-h": "61.2rem",
	"mobile": "0",
	"tablet-sm": "768px",
	"tablet": "1024px",
	"laptop": "1280px",
	"desktop": "1440px",
	"large-desktop": "1920px",
	"VPreloader": "VPreloader_gjfp2",
	"_medium": "_medium_P3ZDA",
	"dot": "dot_i58kL",
	"_base": "_base_z8cdQ",
	"_dark": "_dark_Qnwbc",
	"animloader": "animloader_tIGTj"
};
module.exports = ___CSS_LOADER_EXPORT___;
