

export default {
    name: 'TheToast',

    data() {
        return {
            component: null,
            attrs: null,
            onClose: null,
            key: null,
        };
    },

    beforeMount() {
        this.$toast.event.$on('open', this.open);
        this.$toast.event.$on('close', this.close);
    },

    beforeDestroy() {
        this.$toast.event.$off('open', this.open);
        this.$toast.event.$off('close', this.close);
    },

    methods: {
        open(component, attrs, onClose) {
            this.component = component;
            this.attrs = attrs;
            this.onClose = onClose;
            this.key = Math.random();
        },

        close() {
            this.$toast.event.$off('change');
            this.component = this.attrs = this.key = null;

            if (this.onClose) {
                this.onClose();
                this.onClose = null;
            }
        },
    },


};
