
// Vuex
import { mapState } from 'vuex';

// Utils
import { disablePageScroll, enablePageScroll } from 'scroll-lock/dist/scroll-lock';

export default {
    name: 'TheModal',

    data() {
        return {
            component: null,
            attrs: null,
            onClose: null,
            key: null,
        };
    },

    computed: {
        ...mapState({
            isVisible: state => state.header.isVisible,
        }),

        classList() {
            return [{
                [this.$style[`_${this.attrs?.position}`]]: this.attrs?.position,
                [this.$style._visibleHeader]: this.isVisible,
            }];
        },
    },

    beforeMount() {
        this.$modal.event.$on('open', this.open);
        this.$modal.event.$on('update', this.update);
        this.$modal.event.$on('close', this.close);
    },

    beforeDestroy() {
        this.$modal.event.$off('open', this.open);
        this.$modal.event.$off('update', this.update);
        this.$modal.event.$off('close', this.close);
        enablePageScroll();
    },

    methods: {
        onChange(val) {
            this.$modal.event.$emit('change', val);
            if (val === 'reset') {
                this.close();
            }
        },

        open(component, attrs, onClose) {
            this.component = component;
            this.attrs = attrs;
            this.onClose = onClose;
            this.key = Math.random();
        },

        update(attrs) {
            this.attrs = attrs;
        },

        close() {
            this.$modal.event.$off('change');
            this.component = this.attrs = this.key = null;

            if (this.onClose) {
                this.onClose();
                this.onClose = null;
            }
        },

        beforeEnter() {
            disablePageScroll();
        },

        afterLeave() {
            enablePageScroll();
        },
    },

};
