

/**
 * Компонент-обертка для удобного использования иконок
 *
 * @version 1.0.1
 * @displayName VIcon
 */
export default {
    name: 'VIcon',

    props: {
        name: {
            type: String,
            required: true,
        },

        size: {
            type: String,
            default: 'medium',
            validator: v => [
                'xs',
                'small',
                'pre-medium',
                'medium',
                'semi-large',
                'large',
                '48',
                'custom',
            ].includes(v),
        },
    },

    computed: {
        classList() {
            return {
                [this.$style[`_${this.size}`]]: this.size,
            };
        },
    },
};
