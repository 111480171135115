
// Components
import VProfilePicture from '~/components/ui/profile-picture/VProfilePicture';

export default {
    name: 'VTag',
    components: {
        VProfilePicture,
    },

    props: {
        /**
         * Определяет классы, которые будут модифицировать размер
         */
        size: {
            type: String,
            default: 'small',
            validator: value => [
                'tiny',
                'small',
                'medium',
                'large',
                'paddingLess',
                'custom',
            ].includes(value),
        },

        tag: {
            type: String,
            default: 'div',
            validator: v => [
                'n-link',
                'a',
                'span',
                'div',
                'button',
                'input',
            ].includes(v),
        },

        prefix: {
            type: String,
            default: '',
        },

        label: {
            type: String,
            default: '',
        },

        iconName: {
            type: String,
            default: '',
        },

        /**
         * Определяет классы, которые будут модифицировать цвет
         */
        color: {
            type: String,
            default: 'grey',
            validator: value => [
                'grey',
                'blue',
                'white',
                'club',
                'transparent',
                'transparent-10',
                'club-blue',
                'secondary',
                'gold',
                'silver',
                'bronze',
                'white-10',
                'white-20',
                'base-500-50',
                'black',
                'alert',
                'white-no-border',
                'success',
                'error',
                'custom',
            ].includes(value),
        },

        active: {
            type: Boolean,
            default: false,
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        clickable: {
            type: Boolean,
            default: false,
        },

        iconOnly: {
            type: Boolean,
            default: false,
        },

        /**
         * Счетчик слева
         */
        counterLeft: {
            type: [String, Number],
            default: 0,
        },

        /**
         * Счетчик справа
         */
        counterRight: {
            type: [String, Number],
            default: 0,
        },

        /**
         * Тип изображения профиля
         */
        profileType: {
            type: String,
            default: 'user',
            validator: v => [
                'user',
                'building',
                'image',
            ].includes(v),
        },

        /**
         * Изобаржение профиля (если не null, то немного меняются размеры тега)
         */
        profileImage: {
            type: String,
            default: null,
        },

        /**
         * Предлог
         */
        preposition: {
            type: String,
            default: '',
        },
    },

    computed: {
        classList() {
            return {
                [this.$style._active]: this.active,
                [this.$style._disabled]: this.disabled,
                [this.$style._clickable]: this.clickable,
                [this.$style[`_${this.size}`]]: this.size,
                [this.$style[`_${this.color}`]]: this.color,
                [this.$style._iconOnly]: this.iconOnly,
                [this.$style._isProfile]: this.profileImage !== null,
            };
        },
    },
};
