
// Components
import VCounter from 'portal/components/ui/counter/VCounter.vue';
import VTooltip from 'portal/components/ui/tooltip/VTooltip.vue';

export default {
    name: 'VTabNew',
    components: {
        VTooltip,
        VCounter,
    },

    props: {
        active: Boolean,
        disabled: Boolean,

        tag: {
            type: String,
            default: 'li',
        },

        /**
         * Определяет классы, которые будут модифицировать размер
         */
        size: {
            type: String,
            default: 'medium',
            validator: value => [
                'small',
                'medium',
            ].includes(value),
        },

        /**
         * Определяет классы, которые будут модифицировать цвет
         */
        color: {
            type: String,
            default: 'default',
            validator: value => [
                'base',
                'white',
                'dark',
                'monochrome',
                'white-base-100',
                'success',
                'error',
                'transparent',
            ].includes(value),
        },

        /**
         * Модификатор вида без бэкграунда и с подчеркиванием при активном состоянии
         */
        opened: {
            type: Boolean,
            default: false,
        },


        /**
         * Можно передать название иконки svg-sprite, для отображения в табе
         */
        icon: {
            type: String,
            default: '',
        },

        /**
         * Текст дополнительной информации в тултипе
         */
        info: {
            type: String,
            default: '',
        },

        count: {
            type: [Number, String],
            default: 0,
        },

        /**
         * Пропс, добавляющий скругления
         */
        rounded: {
            type: Boolean,
            default: false,
        },

        /**
         * Пропс, добавляющий форматирование count
         */
        leadingZero: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        classList() {
            return {
                [this.$style._active]: this.active,
                [this.$style._disabled]: this.disabled,
                [this.$style[`_${this.size}`]]: this.size,
                [this.$style[`_${this.color}`]]: this.color,
                [this.$style._opened]: this.opened,
                [this.$style._rounded]: this.rounded,
            };
        },

        isComponent() {
            return this.tag;
        },
    },

    methods: {
        handleClick() {
            if (this.disabled) {
                return;
            }

            this.$emit('click');
        },
    },
};
