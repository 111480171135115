// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NavigationsBlock_JCMIe{background-color:#fff;bottom:0;display:none;padding-bottom:env(safe-area-inset-bottom);position:fixed;width:100%;z-index:100}@media(max-width:767.98px){.NavigationsBlock_JCMIe{display:block}}.NavigationsBlock_JCMIe .menu_l2U7p{align-items:center;display:flex;height:100%;padding:.8rem 0;width:100%}.NavigationsBlock_JCMIe .link_hwGgq{align-items:center;color:#73788c;cursor:pointer;display:flex;flex-direction:column;font-size:1.2rem;font-weight:500;gap:.4rem;letter-spacing:-.048rem;line-height:1.44rem;opacity:.7}.NavigationsBlock_JCMIe .item_unPJb{flex:1}.NavigationsBlock_JCMIe .IconWrapper_nwy98{align-items:center;display:flex;height:2rem;justify-content:center;width:2rem}.NavigationsBlock_JCMIe ._active_qHAos{color:#17181c;opacity:1}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-tablet": "5.6rem",
	"header-h-mobile": "56px",
	"footer-h": "61.2rem",
	"mobile": "0",
	"tablet-sm": "768px",
	"tablet": "1024px",
	"laptop": "1280px",
	"desktop": "1440px",
	"large-desktop": "1920px",
	"NavigationsBlock": "NavigationsBlock_JCMIe",
	"menu": "menu_l2U7p",
	"link": "link_hwGgq",
	"item": "item_unPJb",
	"IconWrapper": "IconWrapper_nwy98",
	"_active": "_active_qHAos"
};
module.exports = ___CSS_LOADER_EXPORT___;
