
import { leadingZero } from '@@/common/assets/js/utils/numbers-utils';


export default {
    name: 'VCounter',
    props: {
        count: {
            type: [Number, String],
            default: 0,
        },

        color: {
            type: String,
            default: 'primary',
            validator: v => [
                'primary',
                'white-primary',
                'white-base',
                'secondary',
                'grey',
                'custom',
            ].includes(v),
        },

        size: {
            type: String,
            default: 'medium',
            validator: v => [
                'medium',
                'custom',
            ].includes(v),
        },

        leadingZero: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        classList() {
            return [
                this.$style[`_${this.color}`],
                this.$style[`_${this.size}`],
            ];
        },

        formattedCount() {
            return this.leadingZero ? leadingZero(this.count) : this.count;
        },
    },
};
