// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".c-tooltip{cursor:pointer}.c-tooltip__content{pointer-events:none;position:absolute;z-index:1001}.c-tooltip__content.is-hoverable{pointer-events:all}.tooltip-enter-active{transition:opacity .3s,transform .3s}.tooltip-leave-active{transition:opacity .15s,transform .15s}.tooltip-enter,.tooltip-leave-active{opacity:0;transform:translateY(-1.6rem)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-tablet": "5.6rem",
	"header-h-mobile": "56px",
	"footer-h": "61.2rem",
	"mobile": "0",
	"tablet-sm": "768px",
	"tablet": "1024px",
	"laptop": "1280px",
	"desktop": "1440px",
	"large-desktop": "1920px"
};
module.exports = ___CSS_LOADER_EXPORT___;
