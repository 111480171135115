
// vuex
import { mapActions, mapState, mapGetters } from 'vuex';

// utils
import { disablePageScroll, enablePageScroll } from 'scroll-lock/dist/scroll-lock';

const BUTTON_LOGIN_TITLE = 'Войти';
const BUTTON_COMPANY_TITLE = 'Создать компанию';

export default {
    name: 'TheHeader',

    components: {
        Logo: () => import('portal/components/common/Logo'),
        NavigationsBlock: () => import('portal/components/common/NavigationsBlock'),
        ProfileMenu: () => import('portal/components/common/profile/ProfileMenu'),
        MenuModal: () => import('portal/components/modals/menu/MenuModal'),
    },

    props: {
        /**
         * Пропс, определяющий, что хедер находится в лейауте профиля
         */
        profile: {
            type: Boolean,
            default: false,
        },

        /**
         * Пропс, определяющий, что происходит загрузка, опредляющая видисоть некоторых элементов
         */
        loading: {
            type: Boolean,
            default: false,
        },

        top: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            lastScroll: 0,
            isTicking: false,

            titles: {
                login: BUTTON_LOGIN_TITLE,
                company: BUTTON_COMPANY_TITLE,
            },
        };
    },

    computed: {
        ...mapState({
            isMenuModalOpened: state => state.header.isMenuModalOpened,
            isProfileMenuOpened: state => state.header.isProfileMenuOpened,
            user: state => state.user?.user,
            profileTitle: state => state.header.profileTitle,
        }),

        ...mapGetters({
            hasUnreadNotifications: 'responses/hasUnreadNotifications',
            isShowUnreadForUnregistered: 'responses/isShowUnreadForUnregistered',
        }),

        isShowNotificationsMarker() {
            if (this.user) {
                return this.hasUnreadNotifications;
            }

            return this.isShowUnreadForUnregistered;
        },

        classList() {
            return {
                [this.$style._topContent]: this.$props.top && !this.isMenuModalOpened,
                [this.$style._profile]: this.profile,
            };
        },

        classListContainer() {
            return this.profile ? 'profile-container' : 'body-container';
        },
    },

    watch: {
        isProfileMenuOpened(val) {
            if (val) {
                disablePageScroll();
            } else {
                enablePageScroll();
            }
        },

        $route() {
            this.fetchNewNotifications();
        },
    },

    mounted() {
        if (this.user) {
            this.loadNotificationsStatuses();
        } else {
            this.getLocalStorageDateForUnregistered();
        }
    },

    methods: {
        ...mapActions('header', [
            'changeVisibility',
            'setMenuModalOpened',
            'setProfileMenuOpened',
        ]),

        ...mapActions({
            loadNotificationsStatuses: 'responses/loadStatusesFromLocalStorage',
            fetchNotifications: 'responses/fetchInit',
            getLocalStorageDateForUnregistered: 'responses/getLocalStorageDateForUnregistered',
        }),

        toggleMenuModalOpened() {
            this.setMenuModalOpened(!this.isMenuModalOpened);
        },

        closeMenuModal() {
            this.setMenuModalOpened(false);
        },

        handleOpenNotificationsModal() {
            if (this.user) {
                this.$modal.open(() => import('portal/components/modals/notifications/NotificationsModal'));

                return;
            }

            this.$modal.open(() => import('portal/components/modals/notifications/NotificationsUnregisteredModal'), {
                position: 'bottom',
            });
        },

        openAuthModal(register = false) {
            this.$modal.open(() => import('portal/components/modals/auth/AuthModal'), {
                position: 'center',
                register,
            });
        },

        toggleProfileMenu() {
            this.setProfileMenuOpened(!this.isProfileMenuOpened);
        },

        closeProfileMenu() {
            this.setProfileMenuOpened(false);
        },

        handleProfileButtonClick() {
            if (this.$deviceIs.pc) {
                this.toggleProfileMenu();
            } else {
                this.$modal.open(() => import('portal/components/common/profile/ProfileMenu'), {
                    modal: true,
                    profile: this.profile,
                });
            }
        },

        handleCreateButtonClick() {
            this.openAuthModal(true);
        },

        async fetchNewNotifications() {
            await this.fetchNotifications();
        },
    },
};
