
// Constants
import { navigations } from 'portal/assets/js/constants/bottomNavbarItemsMobile';
// VUEX
import { mapActions } from 'vuex';
// components
import VIcon from 'portal/components/ui/icon/VIcon.vue';

export default {
    name: 'TheBottomMobileNavbar',
    components: { VIcon },

    data() {
        return {
            navigations,
        };
    },

    methods: {
        ...mapActions('header', [
            'setMenuModalOpened',
        ]),

        setLinkComponent(item) {
            if (!item.to) {
                return 'div';
            }
            return item.to ? 'nuxt-link' : 'a';
        },

        setLinkAttrs(item) {
            return item.to
                ? {
                    to: item.to,
                }
                : {
                    href: item.href,
                    target: '_blank',
                };
        },

        getIsMenuItemActive(item) {
            if (item.to) {
                if (this.$route.path.startsWith(`${item.to}/`)) {
                    return true;
                }

                return this.$route.path === item.to;
            }
        },

        handleMenuItemClick(label) {
            label === 'Меню' ?
                this.setMenuModalOpened(true)
                : this.setMenuModalOpened(false);
        },
    },
};

