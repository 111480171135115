
/**
 * Кастомная альтернатива тега button в стандартном html<br>
 * На проектах, обычно, имеет несколько цветов, форм и состояний.<br><br>
 * Область применения: ссылка, изменения состояния, вызов методов.
 *
 * @version 1.0.1
 * @displayName VButton
 */
export default {
    name: 'VButton',

    props: {
        /**
         * Определяет тег компонента
         */
        tag: {
            type: String,
            default: 'button',
            validator: v => [
                'n-link',
                'a',
                'span',
                'div',
                'button',
                'input',
            ].includes(v),
        },

        /**
         * Определяет классы, которые будут модифицировать размер
         */
        size: {
            type: String,
            default: 'medium',
            validator: value => ['xs', 'small', 'medium', 'large', 'custom'].includes(value),
        },

        /**
         * Определяет классы, которые будут модифицировать цвет
         */
        color: {
            type: String,
            default: 'primary',
            validator: value => [
                'primary',
                'secondary',
                'tertiary',
                'white',
                'white-black',
                'grey',
                'grey-black',
                'opacity-white',
                'transparent',
                'transparent-white',
                'red',
                'red-light',
            ].includes(value),
        },

        /**
         * Это свойство отключает взаимодействие
         */
        disabled: Boolean,

        /**
         * если внутри только иконка
         */
        iconOnly: Boolean,

        /**
         * Включает режим обратного цвета
         */

        inversed: {
            type: Boolean,
            default: false,
        },

        /**
         * Вид кнопки с текстом и иконками по бокам
         */
        textIcon: {
            type: Boolean,
            default: false,
        },

        /**
         * Название иконки в обертке слева (используется в комбинации с пропсом textIcon)
         */
        iconLeft: {
            type: String,
            default: '',
        },

        /**
         * Название иконки в обертке справа (используется в комбинации с пропсом textIcon)
         */
        iconRight: {
            type: String,
            default: '',
        },

        /**
         * Растягивает кнопку на всю ширину
         */
        fullWidth: {
            type: Boolean,
            default: false,
        },

        /**
         * Модификатор вида кнопки в виде квадрата
         */
        square: {
            type: Boolean,
            default: false,
        },

        /**
         * Скругление кнопки
         */
        rounded: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        classList() {
            return [{
                [this.$style[`_${this.color}`]]: this.color,
                [this.$style[`_${this.size}`]]: this.size,
                [this.$style._inversed]: this.inversed,
                [this.$style._disabled]: this.disabled,
                [this.$style._iconOnly]: this.iconOnly,
                [this.$style._textIcon]: this.textIcon,
                [this.$style._fullWidth]: this.fullWidth,
                [this.$style._square]: this.square,
                [this.$style._rounded]: this.rounded,
            }];
        },
    },

    methods: {
        /**
         * Эмитит событие клика в родительский компонент
         * @param {Event} event mouse event
         * @public
         */
        onClick($event) {
            /**
             * Cобытие клика в родительский компонент
             * @event click
             * @param {Event} event mouse event
             */
            this.$emit('click', $event);
        },

        /**
         * Эмитит cобытие при наведении на элемент
         * @param {Event} event mouse event
         * @public
         */
        onMouseEnter($event) {
            /**
             * Cобытие при наведении на элемент
             * @event mouseenter
             * @param {Event} event mouse event
             */
            this.$emit('mouseenter', $event);
        },

        /**
         * Эмитит событие, когда наведение на элемент прекращено
         * @param {Event} event mouse event
         * @public
         */
        onMouseLeave($event) {
            /**
             * Cобытие, когда наведение на элемент прекращено
             * @event mouseleave
             * @param {Event} event mouse event
             */
            this.$emit('mouseleave', $event);
        },
    },
};
