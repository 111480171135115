
export default {
    name: 'VPreloader',

    props: {
        /**
         * Определяет классы, которые будут модифицировать размер
         */
        size: {
            type: String,
            default: 'medium',
            validator: value => [
                'medium',
            ].includes(value),
        },

        /**
         * Определяет классы, которые будут модифицировать цвет
         */
        color: {
            type: String,
            default: 'base',
            validator: value => [
                'base',
                'dark',
            ].includes(value),
        },
    },

    computed: {
        classList() {
            return [
                {
                    [this.$style[`_${this.size}`]]: this.size,
                    [this.$style[`_${this.color}`]]: this.color,
                },
            ];
        },
    },
};
