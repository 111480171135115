
// Vuex
import { mapActions, mapState } from 'vuex';

const FOOTER_REGISTRATION_INFO = 'Дата регистрации: 26 января 2024 г.<br> Серия ИА  № ФС77-86707<br> Учредитель: ООО Движение.ру';
const FOOTER_COPYRIGHT_SYMBOL = '©';
const FOOTER_COPYRIGHT = 'Движение.ру. Все права защищены.';
const FOOTER_INFO_ONE = 'На веб-сайте dvizhenie.ru&nbsp;публикуются в числе прочего материалы информационного агентства «Движение.ру», зарегистрированного в&nbsp;Федеральной службе по надзору в&nbsp;сфере связи, информационных технологий и массовых коммуникаций (Роскомнадзор).';
const FOOTER_INFO_TWO = 'Вся информация, размещенная на данном веб-сайте, предназначена только&nbsp;для персонального пользования и не подлежит дальнейшему воспроизведению и/или распространению в какой-либо форме, иначе как&nbsp;с&nbsp;указанием ссылки на dvizhenie.ru';

export default {
    name: 'TheFooter',

    components: {
        LogoIda: () => import('portal/components/common/LogoIda'),
        ContactsBlock: () => import('portal/components/common/ContactsBlock'),
        NavigationsBlockFooter: () => import('portal/components/common/NavigationsBlockFooter'),
    },

    data() {
        return {
            openedCategories: [],
            isVisible: false,

            text: {
                registrationInfo: FOOTER_REGISTRATION_INFO,
                copyright: FOOTER_COPYRIGHT,
                copyrightSymbol: FOOTER_COPYRIGHT_SYMBOL,
                infoOne: FOOTER_INFO_ONE,
                infoTwo: FOOTER_INFO_TWO,
            },
        };
    },

    computed: {
        ...mapState({
            isTouch: state => state.device.isTouch,
            documents: state => state.documents,
        }),

        currentYear() {
            const today = new Date();

            return today.getFullYear();
        },

        copyright() {
            return `${this.text.copyrightSymbol} ${this.currentYear} ${this.text.copyright}`;
        },
    },

    methods: {
        ...mapActions({
            changeVisibility: 'footer/changeVisibility',
        }),

        onIntersect() {
            return {
                handler: (entries, observer, isIntersecting) => this.handleIntersect(entries, observer, isIntersecting),
                options: {
                    threshold: [0.5],
                },
            };
        },

        handleIntersect(entries, observer, isIntersecting) {
            this.changeVisibility(isIntersecting);
        },

        toggleDocumentCategory(val) {
            if (this.openedCategories.includes(val)) {
                this.openedCategories = this.openedCategories.filter(el => el !== val);
            } else {
                this.openedCategories.push(val);
            }
        },
    },
};
