
// Components
import VIcon from '~/components/ui/icon/VIcon';

export default {
    name: 'TheBottomNavbar',
    components: {
        VIcon,
    },

    computed: {
        items() {
            return [
                {
                    id: 0,
                    label: 'Избранное',
                    icon: 'heart',
                    to: '/',
                },
                {
                    id: 1,
                    label: 'Уведомления',
                    icon: 'bell',
                    to: '/',
                },
                {
                    id: 2,
                    label: 'Сообщения',
                    icon: 'message',
                    to: '/',
                    count: 10,
                },
                {
                    id: 3,
                    label: 'Кабинет',
                    icon: 'user',
                    to: '/',
                },
            ];
        },
    },
};
