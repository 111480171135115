
export default {
    name: 'VTooltipContent',
    props: {
        text: {
            type: String,
            default: '',
        },

        size: {
            type: String,
            default: 'medium',
            validator: v => [
                'medium',
                'custom',
            ].includes(v),
        },

        color: {
            type: String,
            default: 'base',
            validator: v => [
                'base',
                'custom',
            ].includes(v),
        },
    },

    computed: {
        classList() {
            return [
                this.$style[`_${this.size}`],
                this.$style[`_${this.color}`],
            ];
        },
    },
};
