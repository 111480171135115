
// Components
import VTabNew from 'portal/components/ui/tabs/VTabNew.vue';

export default {
    name: 'VTabBarNew',
    components: {
        VTabNew,
    },

    props: {
        /**
         * HTML тег - обертка для табов
         */
        tag: {
            type: String,
            default: 'ul',
            validator: v => [
                'ul',
                'div',
            ].includes(v),
        },

        /**
         * Массив всех табов, каждый из которых может содержать параметры описанные в VTab,
         * а также обязательные параметры label и value
         */
        tabs: {
            type: Array,
            default: () => [],
        },

        facets: {
            type: Array,
            default: null,
        },

        value: {
            type: [Number, String],
            default: null,
        },

        /**
         * Определяет классы, которые будут модифицировать размер
         */
        size: {
            type: String,
            default: 'medium',
            validator: value => [
                'small',
                'medium',
            ].includes(value),
        },

        /**
         * Определяет классы, которые будут модифицировать цвет
         */
        color: {
            type: String,
            default: 'base',
            validator: value => [
                'base',
                'white',
                'dark',
                'monochrome',
                'colorful',
                'white-base-100',
                'transparent',
            ].includes(value),
        },

        /**
         * Модификатор вида без бэкграунда и с подчеркиванием таба при активном состоянии
         */
        opened: {
            type: Boolean,
            default: false,
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        /**
         * Расстянуть табы по всей ширине таб бара
         */
        stretch: {
            type: Boolean,
            default: false,
        },

        /**
         * Пропс, добавляющий скругления
         */
        rounded: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        isComponent() {
            return this.tag;
        },

        classList() {
            return [
                {
                    [this.$style[`_${this.size}`]]: this.size,
                    [this.$style[`_${this.color}`]]: this.color,
                    [this.$style._opened]: this.opened,
                    [this.$style._stretch]: this.stretch,
                    [this.$style._rounded]: this.rounded,
                },
            ];
        },
    },

    methods: {
        checkIfSeparatorIsNeeded(tab, index) {
            const chosenIndex = this.tabs.findIndex(el => el.value === this.value);

            return index !== 0 && this.value !== tab.value && index !== chosenIndex + 1;
        },

        handleGetColor(index) {
            if (this.color === 'colorful') {
                return (index + 1) % 2 ? 'error' : 'success';
            }
            return this.color;
        },
    },
};
