
import clickoutside from '~/directives/clickoutside';

export default {
    name: 'VDropdown',

    directives: {
        clickoutside,
    },

    props: {
        color: {
            type: String,
            default: 'default',
            validator: val => ['default'].includes(val),
        },

        size: {
            type: String,
            default: 'default',
            validator: val => ['default', 'medium'].includes(val),
        },

        isForcedOpen: {
            type: Boolean,
            default: false,
        },

        isForcedClose: {
            type: [Boolean, Number],
            default: false,
        },

        arrow: {
            type: Boolean,
            default: true,
        },

        shadow: {
            type: Boolean,
            default: true,
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        openByHover: {
            type: Boolean,
            default: false,
        },

        rightPosition: Boolean,

        /**
         * Растягивает компонент на всю ширину
         */
        fullWidth: Boolean,
    },

    data() {
        return {
            isOpened: false,
            hoverTimer: null,
        };
    },

    computed: {
        classes() {
            return [
                this.$style[`VDropdown--${this.size}`],
                this.$style[`VDropdown--${this.color}`],
                {
                    [this.$style['is-opened']]: this.isOpened,
                    'v-dropdown--opened': this.isOpened,
                    [this.$style['is-disabled']]: this.disabled,
                    [this.$style['is-shadow']]: this.shadow,
                    [this.$style['is-full-width']]: this.fullWidth,
                    [this.$style['is-rightPosition']]: this.rightPosition,
                },
            ];
        },
    },

    watch: {
        isOpened(val) {
            this.$emit('on-change', val);
        },

        isForcedClose(val) {
            if (val) {
                this.handleClickOutside();
            }
        },

        isForcedOpen(val) {
            if (val) {
                this.handleClick();
            } else {
                this.handleClickOutside();
            }
        },
    },

    mounted() {
        if (this.isForcedOpen) {
            this.handleClick();
        }

        this.$on('item-click', this.choiceValue);
    },

    methods: {
        handleClick() {
            if (this.isOpened) {
                this.isOpened = false;
                this.$emit('close');
            } else {
                this.isOpened = true;
                this.$emit('open');
            }
        },

        choiceValue() {
            this.isOpened = false;
            this.$emit('close');
        },

        handleClickOutside() {
            if (!this.isOpened) {
                return;
            }
            this.isOpened = false;
            this.$emit('close');
        },

        handleMouseEnter() {
            if (this.openByHover && this.$deviceIs.pc) {
                if (this.hoverTimer) {
                    clearTimeout(this.hoverTimer);
                }

                if (!this.isOpened) {
                    this.handleClick();
                }
            }
        },

        handleMouseLeave() {
            if (this.openByHover && this.$deviceIs.pc) {
                if (this.isOpened && this.isForcedClose) {
                    this.handleClickOutside();
                    return;
                }

                this.hoverTimer = setTimeout(() => {
                    if (this.isOpened) {
                        this.handleClickOutside();
                    }
                }, 500);
            }
        },
    },
};
